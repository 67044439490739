<template>
  <div class="content create-written-off-order">
    <div class="form-content">
      <a-row :gutter="16">
        <a-col :span="6">
          <CommonSelect
            :isBu="true"
            :list="$store.state.settlementBuList"
            :code.sync="formData.businessUnitCode"
            :name.sync="formData.businessUnitName"
            :placeholder="$t(`收款组织`)"
          />
        </a-col>
        <a-col :span="6">
          <CommonQuerySelect
            api="getCommonList"
            :placeholder="$t('客户')"
            :code.sync="formData.customerCode"
            :params="{
              businessUnitCode: formData.businessUnitCode,
              tableName: 'customer',
            }"
          />
        </a-col>
        <a-col :span="6">
          <a-button @click="searchHandle(1)" type="primary">
            <i class="iconfont" style="margin-right: 5px">&#xe647;</i>
            {{ $t(`查询`) }}
          </a-button>
        </a-col>
      </a-row>
    </div>
    <div class="opertate-btns flex-ct">
      <a-button type="primary" @click="onSubmit" :loading="saveLoading">
        {{ $t('生成应收核销单') }}
      </a-button>
    </div>
    <div class="receivable-list mt10">
      <div class="infoTitle" style="margin-bottom: 0">
        <div>
          <i class="leftLine"></i>
          <span>{{ $t('应收单列表') }}</span>
        </div>
        <div class="pr20 totla-amount flex-ct" v-if="totalReceivableAmount">
          <span style="font-weight: 400" class="fz14 mr10">{{ $t('本次核销金额') }}:</span>
          <PriceInput :showTxt="true" :value="totalReceivableAmount" />
        </div>
      </div>
      <CommonTable
        ref="CommonTableRef1"
        :columns.sync="columns1"
        :loading="loading1"
        :dataSource="tableData1"
        :showSelect="true"
        :scroll="{ x: 1500, y: scrollHeight }"
        rowKey="docDetailId"
        :selectedRowKeys.sync="selectedRowKeys1"
        :total="total1"
        @select="selectRow1"
        @search="getList1"
      >
        <template v-slot:orderSn="{ record }">
          <a @click="viewDetail1(record)">{{ record.orderSn }}</a>
        </template>
        <template v-slot:receivableAmount="{ record }">
          <PriceInput
            :showTxt="true"
            :currency="record.functionalCurrency"
            :value="record.receivableAmount"
          />
        </template>
        <template v-slot:unWrittenOffAmount="{ record }">
          <PriceInput
            :showTxt="true"
            :currency="record.functionalCurrency"
            :value="record.unWrittenOffAmount"
          />
        </template>
        <template v-slot:amount="{ record }">
          <PriceInput
            :currency="record.functionalCurrency"
            :value.sync="record.amount"
            @blur="
              (val) => {
                onBlur1(val, record)
              }
            "
          />
        </template>
      </CommonTable>
    </div>
    <div class="receipt-note-list mt20">
      <div class="infoTitle" style="margin-bottom: 0">
        <div>
          <i class="leftLine"></i>
          <span>{{ $t('收款单列表') }}</span>
        </div>
        <div class="pr20 totla-amount flex-ct" v-if="totalReceiptAmount">
          <span style="font-weight: 400" class="fz14 mr10">{{ $t('本次核销金额') }}:</span>
          <PriceInput :showTxt="true" :value="totalReceiptAmount" />
        </div>
      </div>
      <CommonTable
        ref="CommonTableRef2"
        :columns.sync="columns2"
        :loading="loading2"
        :dataSource="tableData2"
        :showSelect="true"
        :scroll="{ x: 1500, y: scrollHeight }"
        rowKey="docDetailId"
        :selectedRowKeys.sync="selectedRowKeys2"
        :total="total2"
        @select="selectRow2"
        @search="getList2"
      >
        <template v-slot:receiptNoteSn="{ text, record }">
          <a @click="viewDetail2(record)">{{ text }}</a>
        </template>
        <template v-slot:receiptAmount="{ text, record }">
          <PriceInput :value="text" :currency="record.receiptCurrency" :showTxt="true" />
        </template>
        <template v-slot:unWrittenOffAmount="{ text, record }">
          <PriceInput :value="text" :currency="record.receiptCurrency" :showTxt="true" />
        </template>
        <template v-slot:amount="{ record }">
          <PriceInput
            :value.sync="record.amount"
            :currency="record.receiptCurrency"
            @blur="onBlur2(record)"
          />
        </template>
      </CommonTable>
    </div>
  </div>
</template>

<script>
import { mapGetters, mapMutations } from 'vuex'
import http from '@/service/axios'
import api from '@/service/api'
import CommonTable from '@/component/commonTable'
import { convertKeysToCamelCase } from '@/common'
import debounce from '@/common/debounce'
import { add, subtract } from '@/common/number'
export default {
  name: 'CreateWrittenOffOrder',
  components: { CommonTable },
  data() {
    return {
      formData: {
        businessUnitCode: undefined,
        businessUnitName: undefined,
        customerCode: undefined,
        canWriteOff: true,
      },
      tableData1: [],
      tableData2: [],
      selectedRowKeys1: [],
      selectedRowKeys2: [],
      selectedRows1: [],
      selectedRows2: [],
      total1: 0,
      total2: 0,
      loading1: false,
      loading2: false,
      saveLoading: false,

      columns1: [
        {
          dataIndex: 'orderSn',
          title: this.$t('应收单号'),
          key: 'orderSn',
          scopedSlots: { customRender: 'orderSn' },
        },
        {
          title: this.$t('业务日期'),
          dataIndex: 'bizDate',
          customRender: (text) => text.split(' ')?.[0],
        },
        {
          title: this.$t('单据类型'),
          dataIndex: 'orderType',
          width: 120,
          customRender: (text) => this.receivableOrderTypeMapping[text],
        },
        {
          title: this.$t('客户'),
          dataIndex: 'customerName',
        },
        {
          title: this.$t('应收金额'),
          dataIndex: 'receivableAmount',
          width: 150,
          scopedSlots: { customRender: 'receivableAmount' },
        },
        {
          title: this.$t('未核销金额'),
          dataIndex: 'unWrittenOffAmount',
          width: 150,
          scopedSlots: { customRender: 'unWrittenOffAmount' },
        },
        {
          title: this.$t('本次核销金额'),
          dataIndex: 'amount',
          width: 200,
          scopedSlots: { customRender: 'amount' },
        },
      ],
      columns2: [
        {
          title: this.$t('收款单号'),
          dataIndex: 'receiptNoteSn',
          scopedSlots: { customRender: 'receiptNoteSn' },
        },
        {
          title: this.$t('业务日期'),
          dataIndex: 'bizDate',
          customRender: (text) => text.split(' ')?.[0],
        },
        {
          title: this.$t('单据类型'),
          dataIndex: 'orderType',
          width: 120,
          customRender: (text) => this.receiptNoteTypeMapping[text],
        },
        {
          title: this.$t('客户'),
          dataIndex: 'customerName',
        },
        {
          title: this.$t('收款金额'),
          dataIndex: 'receiptAmount',
          scopedSlots: { customRender: 'receiptAmount' },
          width: 150,
        },
        {
          title: this.$t('未核销金额'),
          dataIndex: 'unWrittenOffAmount',
          scopedSlots: { customRender: 'unWrittenOffAmount' },
          width: 150,
        },
        {
          title: this.$t('本次核销金额'),
          dataIndex: 'amount',
          scopedSlots: { customRender: 'amount' },
          width: 200,
        },
      ],
    }
  },
  activated() {
    this.init()
  },
  computed: {
    ...mapGetters(['receivableOrderTypeMapping', 'receiptNoteTypeMapping']),
    scrollHeight() {
      return window.innerHeight - 780
    },
    totalReceivableAmount() {
      return this.selectedRows1.reduce((total, item) => {
        return add(total, item.amount)
      }, 0)
    },
    totalReceiptAmount() {
      return this.selectedRows2.reduce((total, item) => {
        return add(total, item.amount)
      }, 0)
    },
  },
  methods: {
    ...mapMutations(['addPane']),
    init() {
      this.selectedRowKeys1 = []
      this.selectedRowKeys2 = []
      this.selectedRows1 = []
      this.selectedRows2 = []
      this.searchHandle()
    },
    searchHandle(pageNo) {
      this.searchHandle1(pageNo)
      this.searchHandle2(pageNo)
    },
    searchHandle1(pageNo) {
      this.selectedRowKeys1 = []
      this.$refs.CommonTableRef1.queryList(pageNo)
    },
    searchHandle2(pageNo) {
      this.selectedRowKeys2 = []
      this.$refs.CommonTableRef2.queryList(pageNo)
    },
    async getList1(params) {
      this.loading1 = true
      await http({
        url: api.getReceivableList,
        data: {
          ...this.formData,
          ...params,
        },
        success: (res) => {
          let result = convertKeysToCamelCase(res.result)
          let list = []
          result.list.forEach((item) => {
            item.receiptPlanList.forEach((elem) => {
              let unWrittenOffAmount = subtract(elem.receivableAmount, elem.writtenOffAmount || 0)
              list.push({
                ...item,
                docDetailId: elem.id,
                unWrittenOffAmount,
                amount: unWrittenOffAmount,
              })
            })
          })
          this.tableData1 = list
          this.total1 = res.result.total
        },
      })
      this.loading1 = false
    },
    async getList2(params) {
      this.loading2 = true
      await http({
        url: api.getReceiptNoteList,
        data: {
          ...this.formData,
          ...params,
        },
        success: (res) => {
          let result = convertKeysToCamelCase(res.result)
          let list = []
          result.list.forEach((item) => {
            item.items.forEach((elem) => {
              let unWrittenOffAmount = subtract(elem.receiptAmount, elem.verifiedAmount || 0)
              list.push({
                ...item,
                docDetailId: elem.id,
                unWrittenOffAmount,
                amount: unWrittenOffAmount,
              })
            })
          })
          this.tableData2 = list
          this.total2 = res.result.total
        },
      })
      this.loading2 = false
    },
    viewDetail1(record) {
      this.addPane({
        view: 'receivableDetail/' + record.orderSn,
        name: 'detailSn',
        query: {
          id: record.id,
          sn: record.orderSn,
        },
        route: `/receivableManage/receivableDetail?id=${record.id}&sn=${record.orderSn}`,
      })
    },
    viewDetail2(record) {
      this.addPane({
        view: 'receiptNoteDetail/' + record.receiptNoteSn,
        name: 'detailSn',
        query: {
          id: record.id,
          sn: record.receiptNoteSn,
        },
        route: `/receivableManage/receiptNoteDetail?id=${record.id}&sn=${record.receiptNoteSn}`,
      })
    },
    onSubmit: debounce(async function () {
      if (this.selectedRowKeys1.length === 0) {
        this.$message.warning(this.$t('请选择应收单'))
        return
      }
      if (this.selectedRowKeys2.length === 0) {
        this.$message.warning(this.$t('请选择收款单'))
        return
      }
      this.saveLoading = true
      await http({
        url: api.createWrittenOff,
        data: {
          writtenOffType: 'RECEIVABLE_RECEIPT', //暂时写死 应收收款
          mainDocList: this.selectedRows1.map((item) => ({
            amount: item.amount,
            docDetailId: item.docDetailId,
            docId: item.id,
            docType: 'ReceivableOrder',
          })),
          referDocList: this.selectedRows2.map((item) => ({
            amount: item.amount,
            docDetailId: item.docDetailId,
            docId: item.id,
            docType: 'ReceiptNote',
          })),
        },
        type: 'post',
        hasLoading: true,
        success: (res) => {
          if (res.success) {
            this.$success({
              title: this.$t(`成功`),
              content: this.$t('核销单创建成功'),
            })
            this.init()
          } else {
            this.$error({
              title: this.$t(`错误`),
              content: this.$t(`创建失败`),
            })
          }
        },
      })
      this.saveLoading = false
    }),
    backForm(back = 0) {
      if (back) {
        this.$router.go(-1)
        return
      }
      this.$router.push({ name: 'writtenOffOrderList' })
    },
    selectRow1(selectedRowKeys, selectedRows) {
      this.selectedRowKeys1 = selectedRowKeys
      this.selectedRows1 = selectedRows
    },
    selectRow2(selectedRowKeys, selectedRows) {
      this.selectedRowKeys2 = selectedRowKeys
      this.selectedRows2 = selectedRows
    },
    onBlur1(val, record) {
      if (val && !this.selectedRowKeys1.includes(record.docDetailId)) {
        this.selectedRowKeys1.push(record.docDetailId)
        this.selectedRows1.push(record)
      }
    },
    onBlur2(val, record) {
      if (val && !this.selectedRowKeys2.includes(record.docDetailId)) {
        this.selectedRowKeys2.push(record.docDetailId)
        this.selectedRows2.push(record)
      }
    },
  },
}
</script>
<style scoped>
.bottom-btns {
  justify-content: center;
  margin-top: 30px;
}
.infoTitle {
  justify-content: space-between;
}
</style>
